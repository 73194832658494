@import "styles/fonts.css";
@import "styles/colors.css";

#side_bar_div {
    position: absolute;
    top: 8vh;
    margin-top: 0.5vh;
    height: 100vh;
    bottom: auto;
    /* Tell browser to use a transition when background-color changed */
    transition: width 2s; /* Tell browser to use a transition when background-color changed */
    opacity: 1.0;
    visibility:hidden;
    z-index: 1000;
}

.side_bar_main_bright {
    background: var(--button_color_bright);
    color: var(--button_color_hover_bright);
}

.side_bar_main_dark {
    background: var(--first_color_dark);
    color: var(--button_color_hover_dark);
}

#side_bar_div.shiftback {
    width: 0;
    visibility:hidden;
    animation-direction: normal;
}

#side_bar_div.responsive {
    width: 20vw;
    visibility: visible;
    animation-direction: normal;
}

#side_bar_div.responsive_small {
    width: 18vw;
    visibility: visible;
    animation-direction: normal;
}


#side_bar_div.responsive_large {
    width: 8vw;
    visibility: visible;
    animation-direction: normal;
}

#side_bar_div.responsive_medium {
    width: 18vw;
    visibility: visible;
    animation-direction: normal;
}


#side_bar_div.responsive_mobile {
    width: 70vw;
    visibility: visible;
    animation-direction: normal;
}



.list_item_side_bar {
    list-style-type: none;
    background-color: transparent;
    transition: color 1s;
}

.list_item_side_bar_bright {
    color: var(--text_color_bright);
    border-bottom: 1px solid var(--text_color_bright);
}

.list_item_side_bar_bright:hover {
    color: black;
    border-bottom: 1px solid black;
    cursor: pointer;
}

.list_item_side_bar_dark {
    color: var(--text_color_dark);
    border-bottom: 1px solid var(--text_color_dark);
}

.list_item_side_bar_dark:hover{
    background-color: white;
    color: black;
    cursor: pointer;
}

.list_item_side_bar_large_horizontal {
    font-size: 1.5vw;
    animation-direction: alternate;
    padding: 10%;
    margin: 5% 5% 5% -30%;
    font-family: var(--button_font_type);
}

.list_item_side_bar_medium_horizontal {
    font-size: 1.5vw;
    animation-direction: alternate;
    padding: 10%;
    margin: 5% 5% 5% -30%;
    font-family: var(--button_font_type);
}

.list_item_side_bar_small_horizontal {
    font-size: 2vw;
    animation-direction: alternate;
    padding: 1.8%;
    margin: 4% 4% 4% -30%;
    font-family: var(--button_font_type);
}

.list_item_side_bar_mobile_horizontal {
    font-size: 2vw;
    animation-direction: alternate;
    margin: 5% 5% 5% -30%;
    font-family: var(--button_font_type);
}

.list_item_side_bar_small_vertical {
    font-size: 2vh;
    animation-direction: alternate;
    padding: 10%;
    margin: 5% 5% 5% -30%;
    font-family: var(--button_font_type);
}

.list_item_side_bar_mobile_vertical {
    font-size: 5vw;
    animation-direction: alternate;
    padding: 10%;
    margin: 5% 5% 5% -30%;
    font-family: var(--button_font_type);
}