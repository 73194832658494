@import "styles/colors.css";
@import "styles/fonts.css";

@import "responsive_design/large/Button.css";
@import "responsive_design/medium/Button.css";
@import "responsive_design/small/Button.css";
@import "responsive_design/mobile/Button.css";

.button_main{
    font-weight: bold;
    float: left;
    font-family: var(--button_font_type);
}

.button_bright {
    color: var(--text_color_bright);
    background: var(--button_color_bright);
    border: 0.1vw solid var(--text_color_bright);
}

.button_dark {
    position: relative;
    color: var(--text_color_dark);
    background: var(--button_color_dark);
    border: 0.1vw solid var(--text_color_dark);
}

.button_bright:hover {
    background: var(--button_color_hover_bright);
    color: var(--button_color_bright);
    cursor: pointer;
}

.button_dark:hover {
    background: var(--button_color_hover_dark);
    color: var(--button_text_color_hover_dark);
    cursor: pointer;
}

.react_close_button_inner_dark{
    background-color: var(--text_color_dark);
}

.react_close_button_inner_dark.trigger_hover{
    background-color: var(--text_color_bright);
}

.react_close_button_inner_bright{
    background-color: var(--text_color_bright);
}

.react_close_button_inner_bright.trigger_hover{
    background-color: var(--text_color_dark);
}


.react_close_button_inner2_dark{
    background-color: var(--text_color_dark);
}

.react_close_button_inner2_dark.trigger_hover{
    background-color: var(--text_color_bright);
}

.react_close_button_inner2_bright{
    background-color: var(--text_color_bright);
}

.react_close_button_inner2_bright.trigger_hover{
    background-color: var(--text_color_dark);
}


.react_close_button_outer_circle_bright{
    background-color: rgba(255,0,0,0);

}

.react_close_button_outer_circle_dark{
    border: 3px solid var(--text_color_dark);
    color: var(--button_text_color_hover_dark);
}

.react_close_button_outer_circle_dark.trigger_hover{
    background: var(--button_color_hover_dark);
    border: 3px solid var(--text_color_bright);
    color: var(--button_color_hover_bright);
    cursor: pointer;
}

.react_close_button_outer_circle_bright{
    color: var(--text_color_bright);
    background: rgba(0,0,0,0);
    border: 3px solid var(--text_color_bright);
}

.react_close_button_outer_circle_bright.trigger_hover{
    color: var(--text_color_dark);
    background: rgba(0,0,0,0);
    border: 3px solid var(--text_color_dark);
}

.react_close_button_outer_circle{
    height: 5vh;
    width: 5vh;
    border-radius: 100%;
    position: relative;
}

.react_close_button_inner_x1{
    margin-top: 2.4vh;
    margin-left: 0.6vh;
    rotate: 45deg;
    height: 0.5vh;
    width: 3.8vh;
}
.react_close_button_inner_x2{
    margin-top: -0.6vh;
    margin-left: 0.6vh;
    rotate: -45deg;
    height: 0.5vh;
    width: 3.8vh;
}