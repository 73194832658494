:root {
    --size_heading1: 5vh;
    --size_heading2: 2.5vh;
    --size_heading3: 2vh;
    --size_normal_text: 1vh;
    --size_table_content: 1.8vh;

    --h1_font_type: 'Lato', sans-serif;
    --h2_font_type: 'Lato', sans-serif;

    --button_font_type: Calibri
}