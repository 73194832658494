@import "../styles/fonts.css";
@import "../styles/colors.css";

@import '../responsive_design/mobile/Blog.css';
@import '../responsive_design/small/Blog.css';
@import '../responsive_design/medium/Blog.css';
@import '../responsive_design/large/Blog.css';

.outer_cover{
    position: absolute;
    background-color: var(--first_color_dark);
    left: 0;
    right: 0;
    z-index: 2;
}

.outer_cover_bottom_0{
    bottom: 0;
}

.blog_element_div {
    width: 100%;
    border-radius: 2%;
    padding-bottom: 2%;
    transition: height 1s, width 1s;
}

.blog_element_div_large{
    display: table;
    margin-bottom: 4vh;
}

.blog_element_div_medium{
    display: table;
    margin-bottom: 8%;
}

.close_button_div{
    position: absolute;
    float: right;

    top: -8vh;
    z-index: 5;
}

#blog_preview_card_dark{
    background-color: var(--second_color_dark)
}

#blog_preview_card_bright{
    background-color:  var(--second_color_bright)
}

#blog_date_hashtags_dark{
    color: lightgrey;

}

#blog_date_hashtags_bright{
    color:rgb(100,100,100);
}

.blog_article_structure_table{
    width: 100%;
}

.blog_article_preview_table{
    width: 100%;
}


.blog_header_elements{
    text-align: center;
}

.blog_category_div{
    margin-left: 40%;
}

.blog_card_td1 {
    width: 50%;
}

.blog_card_td2 {
    width: 40%;
    text-align: right;
    vertical-align: center;
}

.blog_category_figure{
    margin-left: 15%;
    height: 25vh;
    border-radius: 5%;
}

.blog_header_div{
    width: 100%;
}

.blog_element_description{
    float: left;
    width: 100%;
    border-radius: 5%;
}

.blog_figure {
    border-radius: 5%;
    cursor: pointer;
    transition: height 1s, width 1s;
}

.text_name_circle{
    width: 100%;
    height: 100%;
    vertical-align: middle;
    color: white;
    text-align: center;
}

.name_circle{
    background-color: var(--blog_icon_color);
    border-radius: 100%;
    float: left;
}


#blog_heading_dark{
    color: var(--text_color_dark);
}

#blog_heading_bright{
    color: var(--text_color_bright);

}

#blog_heading_article_dark{
    color: var(--text_color_dark);
    border-bottom: 2px solid var(--text_color_dark);
}

#blog_heading_article_bright{
    color: var(--text_color_bright);
    border-bottom: 2px solid var(--text_color_bright);

}

.category_div{
    float: left;
}

.article_title{
    text-align: center;
    font-size: 4vh;
    margin-top: 1vh;
    margin-left: 2vh;
    cursor:pointer;
}

.article_title_preview{
    font-size: 3vh;
    margin-top: 1vh;
    margin-left: 2vh;
    cursor:pointer;
}

.short_description{
    text-align: justify;
    width: 50%;
}

.hashtags{

    margin-top: 1vh;
    margin-left: 2vh;
    font-size: 2vh;
}


.date{
    margin-left: 2vh;
    font-size: 1.3vh;
    color: var(--blog_hash_color);
}

.date_mobile{
    margin-left: 2vh;
    font-size: 2vh;
    text-align: center;
    color: var(--blog_hash_color);
}

#read_more_button{
    margin-top: 5%;
    margin-left: 2vh;
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 1;
}


#main_div_blog_article.hidden{
    visibility: hidden;
    opacity: 0;
}

#main_div_blog_article.visible{
    visibility: visible;
    opacity: 1;
}

#main_div_blog_article{
    margin-left: 20%;
    top: 12vh;
    width: 65%;
    opacity: 1;
    height: inherit;
    z-index: 2;
    transition: opacity 1s;
}


#main_div_blog_article_mobile{

    margin-left: 5%;
    top: 12vh;
    width: 90%;
    opacity: 1;
    height: inherit;
    z-index: 2;
    transition: opacity 1s;
}


.main_blog_article_dark{
    background-color: var(--second_color_dark);
    color: var(--text_color_dark);

}

.main_blog_article_large{
    padding-bottom: 8vh;
}


.main_blog_article_medium{
    padding-bottom: 6vh;
}

.main_blog_article_small{
    padding-bottom: 25vh;
}

.main_blog_article_mobile{
    padding-bottom: 15vh;
}

.main_blog_article_bright{
    background-color: var(--second_color_bright);
    color: var(--text_color_bright);
}

.blog_article_content_div{
    margin-top: 20px;
    text-align: justify;
}


.article_div{
    position: absolute;
    visibility: hidden;
    width: 100%;
    z-index: 10;
}

.close_button_div{
    margin-top: -2vh;
    margin-left: 98%;
    padding-bottom: 1vh;
}