@import "styles/colors.css";

.content_form_div {
    visibility: hidden;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    opacity: 0;
    transition: opacity 1s; /* Tell browser to use a transition when background-color changed */
    z-index: 3;
    margin-bottom: 20vh;
}

#content_form_color_dark {
    color: grey;
    background-color: black;
}

#content_form_color_bright {
    color: black;
    background-color: white;
}


.thesis_request_table{
    text-align: left;
    table-layout: fixed;
    width: 60%;
}

.thesis_request_table_mobile{
    text-align: left;
    table-layout: fixed;
    width: 100%;
}

.thesis_request_td{
    width: 20%;
}

.thesis_request_td_mobile{
    width: 30%;
}

.contact_form_label_dark{
    color: var(--text_color_header_dark);
}

.contact_form_label_bright{
    color: var(--text_color_header_bright);
}

.contact_form_label_mobile{
    font-size: 2.2vh;
}

.contact_form_label_small{
    font-size: 2.1vh;
}

.contact_form_label_medium{
    font-size: 3vh;
}

.contact_form_label_large{
    font-size: 3vh;
}

.input_field{
    width: 100%;
    height: 4vh;
    font-size:15px;
}

#send_button {
    width: 20%;
}

#send_button_mobile {
    width: 100%;
}

.input_area {
    width: 100%;
    height: 10vh;

    font-size:15px;
}

.content_form_div.animate{
    opacity: 0.97;
    top: 0;
    left: 0;
    right: 0;
    font-size: 12px;
    visibility: visible;
    animation-direction: alternate;
}

.content_form_div.invisible{
    height: 0;
}

.content_form_div.visible{
    margin-top: 8vh;
    height: 100%;
}

.content_form_div.visible_mobile{
    margin-top: 12vh;
    height: 100%;
}

.heading_div{
    float: left;

}

.close_button_div{
    float: right;
}

.close_button_div_large{
    padding-top: 10vh;
    padding-bottom: 1vh;
    margin-right: 10%;
    z-index: 5;
}

.close_button_div_medium{
    padding-top: 10vh;
    padding-bottom: 1vh;
    margin-right: 10%;
    z-index: 5;
}

.close_button_div_small{
    padding-top: 11vh;
    padding-bottom: 1vh;
    margin-right: -10%;
    z-index: 5;
}

.close_button_div_mobile{
    padding-top: 20vh;
    padding-bottom: 1vh;
    margin-right: -7%;
    z-index: 5;
}

.contact_form_inner_div {
    margin-left: 20%;
    margin-right: 20%;
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    z-index: 4;
}

.contact_form_inner_div_mobile {
    margin-left: 10%;
    margin-right: 10%;
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    z-index: 4;
}

#content_div{
    width: 80%;
    margin-left: 10%;
}