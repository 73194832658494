@import "../styles/colors.css";


#header-bright {
    color: var(--text_color_header_bright);
}

#header-dark {
    color: var(--text_color_dark);
}

.about_me_header {
    font-size: 5vh;
    margin-bottom: 1vh;
}

.main_content_div {
    margin-top: 8vh;
}

.profile_picture {
    border-radius: 30%;
    padding: 20px;
    width: 15%;
    transition: width 2s;
}

.profile_picture:hover {
    width: 20%;
    animation-direction: reverse;
}

.cv_div {
    position: relative;
    left: 10px;
    right: 10px;
}

#cv-div-dark {
    background-color: var(--first_color_dark);
    padding-bottom: 8vh;
    border-radius: 2%;
}

#cv-div-bright {
    background-color: var(--second_color_bright);
}

.cv_table {
    margin-left: auto;
    margin-right: auto;
}

.tr_cv_content-bright {
    font-size: 1.8vh;
    color: var(--text_color_bright);
    left: 10%;
    right: 10%;
}

.tr_cv_content-dark {
    font-size: 1.8vh;
    color: var(--text_color_dark);
    left: 10%;
    right: 10%;
}


.th_about_me {
    text-align: left;
    padding-left: 1vw;
    padding-top: 2vw;
    font-size: 4vh;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.025em;
    z-index: 1;
    text-transform: uppercase;

    opacity: 0.8;
}

#about_me_td_bright {
    color: var(--text_color_bright);
}

#about_me_td_dark {
    color: white;
}

td {
    padding-top: 3vh;
    text-align: left;
    padding-left: 1vw;
}

.bio_table_row {
    width: 80vh;
    font-size: 1.8vh;
    padding-top: 2vh;
}

#bio-table-row-dark {
    color: white;
}

#bio-table-row-bright {
    color: var(--text_color_bright);
}

.tr_cv_content {
    font-size: var(--size_table_content);
    color: var(--text_color_bright);
}

.td_cv_content_first_row {
    width: 70%;
    padding-top: 2vh;
}

.date_row {
    width: 25%;
    padding-top: 1vh;
    text-align: right;
    padding-right: 4vh;
}

.data_row_bright {
    color: var(--text_color_bright);
}

.data_row_dark {
    color: var(--text_color_dark);
}

.title_row_bright {
    color: var(--text_color_bright);
}

.title_row_dark {
    color: var(--text_color_dark);
}