.main-content-div_mobile {
    margin-right: 20%;
    margin-left: 20%;
}

.master_div_mobile{
    margin-top: 7vh;
    padding-bottom: 15vh;
}

.main_content_div_mobile {
    margin-right: 5%;
    margin-left: 5%;
}

.subdiv_mobile {
    margin-left: 1%;
    margin-right: 1%;
    border-radius: 5%;
}

.default_text_div_mobile {
    font-size: 14px;
}

.h3_mobile{
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
}