@import '../styles/colors.css';

@import '../responsive_design/mobile/Research.css';
@import '../responsive_design/small/Research.css';
@import '../responsive_design/medium/Research.css';
@import '../responsive_design/large/Research.css';

.research_table_div {
    position: relative;
    left: 10px;
    right: 10px;
}

.research_table{
    margin-left: auto;
    margin-right: auto;
}

.td_research_items{
    width: 90vh
}

.show_publication_info_button {
    background-color: var(--button_color_dark);
    color: var(--text_color_dark);
    opacity: 0.5;
    width: 10vh;
    z-index: 100;
}

.show_publication_info_button:hover {
    cursor: pointer;
    background-color: var(--button_color_hover_dark);
    color: var(--text_color_dark_hover);
    opacity: 0.5;
}

.publication_info_item_large{
    font-size: 25px;
}

.publication_info_item_mobile{
    font-size: 10px;
    width: 1vh;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.table_publication_info_dark {
    background-color: var(--first_color_dark);
    color: white;

}

.table_publication_info_bright {
    background-color:  var(--first_color_bright);
    color: black;
}

.collapsible_header_bright {
    color: var(--text_color_bright);
}

.collapsible_header_dark {
    color: var(--text_color_dark);
}

.card {
    height: auto;
    min-height: 10px;
}

