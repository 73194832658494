.outer_cover_mobile{
    top: 10vh;
}

.name_circle_mobile{
    border: 4px solid rgba(0,0,0, 0.5);
    margin-left: 2vh;
    height: 5vh;
    width: 5vh;
}

.text_name_circle_mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.article_title_mobile{
    text-align: center;
    color: white;
    font-size: 3vh;
    margin-left: 5%;
    margin-right: 15%;
    margin-top: 1vh;
    cursor:pointer;
    border-bottom: 2px solid grey;
}

.article_title_preview_mobile{
    font-size: 3vh;
    text-align: center;
    margin-top: 1vh;
    cursor:pointer;
}

#read_more_button_mobile{
    margin-top: 1%;
    margin-right: 20%;
    margin-left: 20%;
    width: 60%;
    font-size: 2vh;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 1;
}

.blog_article_content_div_mobile{
    margin-top: 20px;
    margin-right: 8%;
    text-align: justify;
}

.short_description_mobile{
    margin-left: 10%;
    margin-right: 10%;
    text-align: justify;
    padding-top: 5%;
    color: white;
}

.hashtags_mobile{
    margin-top: 1vh;
    margin-left: 2vh;
    font-size: 2vh;
    text-align: center;
}

.category_div_mobile{
    padding-top:1.5vh;
    padding-left: 2vh;
    font-size:2.5vh;
    float: left;
}

.blog_element_div_mobile {
    width: 100%;
    overflow: auto;
    border-radius: 3%;
    transition: height 1s, width 1s;
    margin-bottom: 2vh;
}

.blog_element_description_mobile{
    width: 100%;
    border-radius: 5%;
}

.blog_figure_mobile {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    border-radius: 5%;
}

.close_button_div_article_mobile{
    position: absolute;
    float: right;
    left: 88%;
    top: 0;
    z-index: 5;
}