.button_mobile{
    padding: 1.8vh;
    margin: 0.8vh;
    font-size: 1.9vh;
}

.button_header_mobile{
    padding: 1.8vh;
    margin: 0.8vh;
    font-size: 1.9vh;
}