@import "../styles/colors.css";

.teaching_heading_th{
    padding-top: 2%;
    padding-left: 2%;
    text-align: left;
}

.profile_picture {
    border-radius: 30%;
    padding: 20px;
    width: 15%;
}

.tr_cv_content_bright {
    color: var(--text_color_bright);
}

.tr_cv_content_dark {
    color: var(--text_color_dark);
}

.td_cv_content_first_row {
    padding-top: 2vh;
}

.teaching_main_div-bright.page_load{
    opacity: 1.0;
}

.teaching_main_div-dark.page_load{
    opacity: 1.0;
}

.about_me_header {
    font-size: 5vh;
    margin-bottom: 1vh;
}

.cv_div {
    position: relative;
    left: 10px;
    right: 10px;
}

.teaching_table{
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1vh;
}

.teaching_sub_th-bright{
    font-size: 2vh;
    color: var(--text_color_header_bright);
}

.teaching_sub_th-dark{
    font-size: 2vh;
    color: var(--text_color_header_dark);
}

.subheader_teaching-bright {
    font-size: 4vh;
    color: var(--text_color_header_bright);
}

.subheader_teaching-dark {
    font-size: 4vh;
    color: var(--text_color_header_dark);
}

.td_cv_content_first_row {
    padding-top: 1vh;
    text-align: left;
    padding-left: 1vw;
}

.td_cv_content_first_row_teaching {
    padding-top: 0vh;
    text-align: left;
    padding-left: 1vw;
}
