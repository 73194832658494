@import '../responsive_design/mobile/Imprint.css';
@import '../responsive_design/small/Imprint.css';
@import '../responsive_design/medium/Imprint.css';
@import '../responsive_design/large/Imprint.css';

.imprint_div {
    width: 100%;
    height: 0;
    padding-bottom: 100vh;
    visibility: hidden;
    top: 0;
    color: grey;
    position: absolute;
    background-color: black;
    opacity: 0;
    transition: opacity 1s; /* Tell browser to use a transition when background-color changed */
    z-index: 3;
}

.close_button_div{
    position: absolute;
    float: right;
    right: -3vh;
    top: 7vh;
    z-index: 5;
}

.imprint_div.animate{
    opacity: 0.93;
    width: 100%;
    visibility: visible;
    animation-direction: alternate;
}

.imprint_div.invisible{
    height: 0;
}

.imprint_div.visible{
    height: 200%;
}

.imprint_inner_div {
    padding-top: 10vh;
    position: absolute;
    z-index: 4;
}

#content_div{
    width: 50%;
    margin-left: 25%;
}

#exit_button{
    margin-left: 47.5%;
    width: 5%
}

.close_button_div_imprint{
    float: right;
    margin-left: 85%;
    position: absolute;
}