.welcome_div_medium{
    width: 50%;
    margin-left: 20%;
    margin-right: 20%;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 12vh;
    font-size: 20px;
}

.div_profile_picture-first-page_medium {
    width: 40%;
}

.div_profile_picture-first-page_medium_horizontal {
    float: left;
}

.introduction_div_horizontal{
    float: right;
}

.start_page_div_container_medium_horizontal {
    display: flex;
    padding-top: 2vh;
}

.start_page_div_container_medium_vertical {
}


.introduction_div_medium{
    font-size: 0;
    margin-top: -3vh;
    margin-left: 5%;
    margin-right: 5%;
    padding: 1vh 2vh 8vh;
}



.profile_picture-first-page_medium_horizontal{
    height: 20%;
    padding-top: 0;
    margin-right: -50%;
}


.profile_picture-first-page_medium{
    border-radius: 10%;
    transition: height 2s;
}

.profile_picture-first-page_medium:hover{
    height: 25%;
    direction: inherit;
}



.self_description_div_medium{
    font-size: 25px;
}

.slider_div_medium {
    height: 25vh;
}

.welcome_header_medium {
    font-size: 3.5vh;
}