@import "styles/colors.css";
@import "styles/fonts.css";

@import "responsive_design/large/Main.css";
@import "responsive_design/medium/Main.css";
@import "responsive_design/small/Main.css";
@import "responsive_design/mobile/Main.css";

.master_div {
    position: absolute;
    width: 100%;
    min-height: 90%;
}

.master_div_dark {
    background-color: var(--first_color_dark);
}

.master_div_bright {
    background-color: var(--first_color_bright);
}

.subdiv_dark {
    background-color: var(--second_color_dark);
}

.subdiv_bright {
    background-color:  var(--second_color_bright);
}

.a_dark {
    color: white;
}

.a_dark:hover{
    color: darkgrey;
}

.a_bright {
    color: black;
}

.a_bright:hover{
    color: darkgrey;
}

.heading1_dark {
    font-size: var(--size_heading1);
    color: var(--text_color_header_dark);
}

.heading1_bright {
    font-size: var(--size_heading1);
    color: var(--text_color_header_bright);
}

.heading2{
    text-align: left;
    padding-top: 1%;
    padding-left: 2%;
}

.heading2_bright {
    font-size: var(--size_heading2);
    border-bottom: 2px solid var(--text_color_bright);
    color: var(--text_color_bright);
}

.heading2_dark {
    font-size: var(--size_heading2);
    border-bottom: 2px solid var(--text_color_dark);
    color: var(--text_color_dark);}

.heading3{
    text-align: left;
    padding-top: 1vh;
}

.heading3_bright {
    font-size: var(--size_heading3);
    color: var(--text_color_header_bright);
}

.heading3_dark {
    font-size: var(--size_heading3);
    color: var(--text_color_header_dark);
}

.introduction_heading_horizontal{
    margin-left: 20%;
    margin-right: 10%;
}