.outer_cover_medium{
    top: 14vh;
}

.blog_category_figure_medium{
    margin-left: 15%;
    height: 25vh;
    border-radius: 5%;
}

.name_circle_medium{
    border: 4px solid rgba(0,0,0, 0.5);
    margin-left: 2vh;
    height: 4vh;
    width: 4vh;
    margin-top: 1vh;
}

.text_name_circle_medium{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
}

.short_description_medium{
    margin-left: 2vh;
    width: 50%;
}

.read_more_button_div_medium{
    margin-left: 1vh;
}

.category_div_medium{
    padding-top: 1.7vh;
    padding-left: 2vh;
    font-size:2.5vh;
    float: left;
}

.blog_figure_medium {
    float: right;
    height: auto;
    width: 95%;
    margin-top: 3.5vh;
    border-radius: 5%;
    cursor: pointer;
}

.blog_figure_medium:hover {
    width: 100%;
    height: auto;
}

.close_button_div_article_medium{
    position: absolute;
    float: right;
    left: 82%;
    z-index: 5;
}