.footer_medium {
    height: 3vh;
}

.copyright_box_medium{
    left: 5vh;
    font-size: 15pt;
    padding: 0
}

.social_media_medium{
    font-size: 1.8em;
    margin-top: -1.5vh;
    margin-left: 1vh;
}

.logo_box_medium{
    right: 15vh;
}