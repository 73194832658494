@import url(http://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);

#subdiv_large {
    margin-left: 1%;
    margin-right: 1%;
    border-radius: 5%;
}

.master_div_large{
    margin-top: 6vh;
    padding-bottom: 10vh;
}

.main_content_div_large {
    margin-right: 20%;
    margin-left: 20%;
}

.default_text_div_large {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
}

.h3_large{
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
}

.heading2_large{
    font-family: Arial, Helvetica, sans-serif;
}