.button_header_large{
    padding: 0.8vh;
    margin: 0.6vh;
    font-size: 1.9vh;
}

.button_large{
    padding: 1vh;
    margin: 0.6vh;
    font-size: 1.9vh;
}