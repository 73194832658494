@import "styles/colors.css";
@import "styles/fonts.css";

.bg_image {
    position: absolute;
    top: 0;
    width: 100%;
    transition: transform 2s;
}

.content-frame-dark {
    background-color: transparent;
    padding-bottom: 8vh;
}

.content-frame-bright {
    background-color: transparent;
    padding-bottom: 6vh;
}

#bg1 {
    position: absolute;
    left: 0;
}

#bg2 {
    position: absolute;
    left: -100%;
}

.bg_image.shift {
    transform: translateX(100%)
}


.background_cover {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    transition: opacity 1s;
    z-index: 1;
    visibility: hidden;
}

#background-cover-dark {
    background-color: var(--second_color_dark);
}

#background-cover-bright {
    background-color: var(--second_color_bright);
}

.headers {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.0;
    transition: opacity 3s;
    z-index: 0;
}

.headers.page_load {
    opacity: 1.0;
}

.background_cover.responsive {
    opacity: 0.8;
    visibility: visible;
}


h1 {
    text-align: center;
    color: white;
    margin: 0;
    font-family: var(--h1_font_type);
    letter-spacing: 0.025em;
    z-index: 1;
    text-transform: uppercase;
    opacity: 1;
    transition: color 1s;
}


h2 {
    opacity: 0.8;
    font-size: 20vh;
    font-family: var(--h2_font_type);
    color: lightgray;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    z-index: 1;
}