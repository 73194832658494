@import '../styles/colors.css';

@import '../responsive_design/mobile/StartPage.css';
@import '../responsive_design/small/StartPage.css';
@import '../responsive_design/medium/StartPage.css';
@import '../responsive_design/large/StartPage.css';

.subdiv{
    z-index: 8;
}

.welcome_div{
    position: absolute;
    block-size: fit-content;

    border: 1px solid var(--text_color_dark);
    background: var(--welcome_div_background_color);
    z-index: 1;

    transition: opacity 2s ease-in;
}

.welcome_div_visible{
    opacity: 1;
}

.welcome_div_invisible{
    opacity: 0;
}

.welcome_div.animate {
    opacity: 1;
}

.introduction_div{
    border-radius: 5%;
}

.introduction_div_bright{
    background-color: var(--second_color_bright);
}

.introduction_div_dark{
    background-color: var(--second_color_dark);
}

.self_description_div {
    text-align: justify;
}

.welcome_header{
    position: relative;
    transition: font-size 1s;
}

.welcome-header-dark{
    color: var(--text_color_dark);
}

.welcome-header-bright{
    color: var(--text_color_bright);
}

.self-description-div_dark{
    color: var(--text_color_dark);
}

.self-description-div_bright{
    color: var(--text_color_bright);
}