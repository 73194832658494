:root {
    --first_color_bright: rgb(254,254,254);
    --second_color_bright: rgb(245,245,245);
    --text_color_header_bright: rgb(110,110,110);
    --text_color_bright: rgb(50,50,50);
    --button_color_bright: rgba(255, 255, 255, 0.4);
    --button_color_hover_bright: rgb(0,0,0,0.5);

    --first_color_dark: rgb(1, 4, 9, 1.0);
    --second_color_dark: rgb(13,17,23);

    --text_color_dark_hover: black;
    --text_color_dark: rgb(255,255,255);
    --text_color_header_dark: rgb(255,255, 255);
    --button_color_dark: rgba(0, 0, 0, 0.8);
    --button_color_hover_dark: rgb(255,255, 255);
    --button_text_color_hover_dark: rgba(1, 4, 9, 1.0);

    --welcome_div_background_color: rgba(0,0, 0, 0.3);
    --link_color_bright: 'black';
    --link_color_bright_hover: 'lightgrey';

    --link_color_dark: 'white';
    --link_color_dark_hover: 'lightgrey';

    --blog_icon_color: rgba(255,0,0, 0.5);
    --blog_hash_color: 'lightgrey';
}
