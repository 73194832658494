@import "styles/colors.css";
@import "responsive_design/large/Header.css";
@import "responsive_design/medium/Header.css";
@import "responsive_design/small/Header.css";
@import "responsive_design/mobile/Header.css";


#header2_bright {
  background: var(--first_color_bright)
}

#header2_dark {
background: var(--first_color_dark)

}

.header {
  position:fixed;
  top: 0;
  z-index: 6;
}

.header.set_bg_color {
  transition: opacity 1s;
}

#button1 {
  position: relative;
  margin-left: auto;
  float:left;
}

.headerElements{
  margin-left: 0;
  margin-right: auto;
}

.side_bar_selector{
  position: relative;
  float:left;
  transition: transform 1s;
}

.side_bar_selector.responsive {
  transform: rotate(90deg)
}

.side_bar_selector:hover{
  cursor: pointer;
}

.side_bar_selector_bright {
  border: 0.1vh solid  var(--text_color_bright);
}

.side_bar_selector_dark {
  border: 0.1vh solid  var(--text_color_dark);
}

.side_bar_stripe_bright{
  background-color: var(--text_color_bright);
}

.side_bar_stripe_dark{
  background-color: var(--text_color_dark);
}

#side_bar_stripe1 {
  transition: transform 1s;
}

#side_bar_stripe1.responsive {
  transform: translate(0px, 1.1vh) rotate(45deg) ;
}

#side_bar_stripe2 {
  transition: opacity 1s;
}

#side_bar_stripe2.responsive {
  opacity: 0.0;
}

#side_bar_stripe3 {
  transition: transform 1s;
}

#side_bar_stripe3.responsive {
  transform: translate(0px, -1vh) rotate(-45deg) ;
}

.change_color_icon{
  position: relative;
  float:left;
  transition: margin-left 0.5s; /* Tell browser to use a transition when background-color changed */
}

.change_color_icon_bright{
  color: var(--text_color_bright);
}

.change_color_icon_dark{
  color: var(--text_color_dark);
}

.change_color_icon_dark:hover
{
  color: var(--text_color_dark_hover);
  cursor: pointer;
}

.change_color_icon_bright:hover
{
  color: var(--button_color_hover_dark);
  cursor: pointer;
}