.outer_cover_small{
    top: 14vh;
}

.text_name_circle_small{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.name_circle_small{
    border: 8px solid rgba(0,0,0, 0.5);
    margin-left: 4vh;
    margin-top: 2vh;
    margin-bottom: 2vh;
    height: 4vh;
    width: 4vh;
}

.category_div_small{
    padding-top: 2.8vh;
    padding-left: 2vh;
    font-size: 3vh;
    float: left;
}

.blog_figure_small {
    width: 40%;
    margin-left: 30%;
    border-radius: 5%;
    cursor: pointer;
}

.blog_figure_small:hover {
    width: 65%;
}

.close_button_div_article_small{
    position: absolute;
    float: right;
    left: 85%;
    z-index: 5;
}