.header_large {
    width: 100vw;
    height: 6vh;
}

.change_color_icon_large {
    margin-top: 1vh;

    margin-left: 1vh;
    font-size: 3vh;
    padding: 0.3vh;
}

.side_bar_stripe_large {
    height: 0.4vh;
    width: 100%;
    margin: 0.2vh 0.0vw 0.7vh;
    pointer-events: none;
}

.side_bar_selector_large {
    margin: 0.4vh 5vh 1vh 2vw;
    width: 3vh;
    height: 3vh;
    padding: 1vh;
}