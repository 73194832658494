.welcome_div_small{
    width: 70%;
    margin-left: 10%;
    margin-right: 15%;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 5vh;
    font-size: 18px;
}

.div_profile_picture-first-page_small {
    width: 40%;
}


.div_profile_picture-first-page_small_horizontal {
    float: left;
}


.profile_picture-first-page_small{
    border-radius: 30%;
    padding: 20px;
    transition: width 2s;
}

.start_page_div_container_small_horizontal {
    display: flex;
}

.start_page_div_container_small_vertical {

}

.introduction_div_horizontal{
    float: right;
}

.introduction_div_vertical{
    padding: 2vh 2vh 8vh;
}

.profile_picture-first-page_small_vertical{
    width: 50%;
    padding-top: 5%;
    margin-left: 92%;
}

.profile_picture-first-page_small_horizontal{
    height: 10%;
    padding-top: 0;
    margin-right: -50%;
}


.profile_picture-first-page_small:hover{
    height: 14%;
    direction: inherit;
}

.introduction_div_small{
    font-size: 0;
    margin-left: 5%;
    margin-right: 5%;
}

.self_description_div_small{
    font-size: 18px;
}

.slider_div_small {
    height: 34vh;
}

.slider_div_small {
    height: 20vh;
}

.welcome_header_small {
    font-size: 3.5vh;
}