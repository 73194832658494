.markdown th {
    border: 1px solid white
}

.markdown table {
    margin-left: auto;
    margin-right: auto;
}

.markdown img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.markdown td {
    border: 1px solid white
}