.slider {
  overflow: hidden;
  position: relative;

  & .slide-list {
    display: flex;
    height: 100%;
    transition: transform 0.5s ease-in-out;
    width: 100%;

    & .slide {
      flex: 1 0 100%;
      position: relative;

      & .slide-image {
        display: flex;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      & .slide-title {
        text-align: center;
        margin-top: 10px;
      }
    }
  }

  /* DOTS */

  & .dots {
    display: flex;
    position: absolute;
    top: 90%;
    width: 100%;
    /* Center child horizontally*/
    display: flex;
    justify-content: center;
    z-index: 2;

    & .dot {
      background-color: #e7e7e7;
      border-radius: 20px;
      border: 2px grey solid;
      height: 10px;
      margin: 5px;
      width: 10px;

      &:hover {
        cursor: pointer;
      }

      &.selected {
        background-color: black;
      }
    }
  }

  /* ARROWS */

  & .arrows {
    color: white;
    display: flex;
    font-size: 30px;
    justify-content: space-between;
    height: 100%;
    position: absolute;
    top: 30%;
    width: 100%;
    z-index: 1;

    & .arrow {
      height: 30px;
      width: 30px;

      &:hover {
        cursor: pointer;
      }

      &.left {
        background-image: url(../../assets/icons/arrow.png);
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: 5px;
        transform: rotate(
180deg);
      }

      &.right {
        background-image: url(../../assets/icons/arrow.png);
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 5px;
      }
    }
  }
}