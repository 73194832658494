.footer_large {
    height: 3vh;
}

.copyright_box_large{
    left: 5vh;
    font-size: 20pt;
    padding: 0
}

.social_media_large{
    font-size: 3em;
    margin-top: -1vh;
    margin-left: 1vh;
}

.logo_box_large{
    right: 15vh;
}