.outer_cover_large{
    top: 14vh;
}

.blog_category_figure_large{
    margin-left: 15%;
    height: 30vh;
    border-radius: 5%;
}

.name_circle_large{
    border: 8px solid rgba(0,0,0, 0.5);
    margin-left: 2vh;
    height: 4vh;
    width: 4vh;
    margin-top: 1vh;
}

.text_name_circle_large{
    padding-top: 20%;
    font-size: 30px;
}

.short_description_large{
    margin-left: 2vh;
    width: 50%;
}

.read_more_button_div_large{
    margin-left: 1vh;
}

.category_div_large{
    padding-top: 1.9vh;
    padding-left: 2vh;
    font-size:2.5vh;
    float: left;
}

.blog_figure_large {
    height: 35vh;
    width: 53vh;
    border-radius: 5%;
    cursor: pointer;
    float: right;
}

.blog_figure_large:hover {
    height: 37vh;
    width: 56vh;
}

.close_button_div_article_large{
    position: absolute;
    float: right;
    left: 82%;
    z-index: 5;
}