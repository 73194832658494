.table_publication_info_medium{
    padding-bottom: 1vh;
    width: 95%;
    margin-left: 2vh;
}

.collapsible_header_medium{
    font-size: 18px;
}

.publications_table_row-bright {
    width: 50vw;
    color: var(--text_color_bright);
}