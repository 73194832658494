.social_media_small{
    font-size: 1.4em;
    margin-left: 0.5vh;
    margin-top: -0.9vh;
}

.copyright_box_small{
    left: 2vh;
    font-size: 10pt;
    padding: 1.5vh
}

.footer_small {
    height: 3vh;
}


.logo_box_small{
    right: 15vh;
}