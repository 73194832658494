@import '../responsive_design/mobile/Projects.css';
@import '../responsive_design/small/Projects.css';
@import '../responsive_design/medium/Projects.css';
@import '../responsive_design/large/Projects.css';

.publications_table_row-dark {
    width: 50vw;
    color: var(--text_color_dark);
}

.publications_table_row-bright {
    width: 50vw;
    color: var(--text_color_bright);
}

.projects_header_dark{
    font-size: 2.5vh;
    text-align: left;
    color: var(--text_color_header_dark);
}

.projects_header_bright{
    font-size: 2.5vh;
    color: var(--text_color_header_bright);
}

.projects_main_div-dark {
    position: absolute;
    margin-top: 8vh;
    width: 100%;
    padding-bottom: 10vh;
    padding-left: 10%;
    padding-right: 10%;
    background-color: var(--second_color_dark);
}

td {
    padding-top: 0;
    text-align: left;
    padding-left: 1vw;
}


.project_picture {
    border-radius: 5%;
    padding: 10px;
    width: 80%;
    transition: width 1s;
}

.project_picture:hover {
    width: 100%;
    animation-direction: reverse;
}

.other_projects_th-dark{
    padding-top: 10vh;
    color: var(--text_color_header_dark);
}

.other_projects_th-bright{
    padding-top: 10vh;
    color: var(--text_color_header_bright);
}

.projects_element_div {
    width: 100%;
    border: 1px solid black;
}

.project_figure_td {
    width: 50%;
    text-align: center;
    vertical-align: top;
}