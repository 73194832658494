@import "styles/colors.css";
@import "responsive_design/large/Footer.css";
@import "responsive_design/medium/Footer.css";
@import "responsive_design/small/Footer.css";
@import "responsive_design/mobile/Footer.css";


.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 6;
}

.footer_bright {
    background-color: var(--first_color_bright);
    padding: 10px;
    color: var(--text_color_header_bright);
}

.footer_dark {
    background-color: var(--first_color_dark);
    padding: 10px;
    color: var(--text_color_header_dark);
}

#footer_button {
    position: absolute;
    right: 5vh;
    margin-top: 0;
    padding: 0.4vh
}

.copyright_box {
    position: absolute;
}

.copyright_box_bright {
    color: black;
}

.copyright_box_black {
    color: white;
}

.logo_box {
    position: absolute;
    padding: 1vh
}

.social_media{
    position: relative;
}

.social_media_dark {
    color: var(--text_color_dark);
}


.social_media_bright {
    color: var(--text_color_bright);
}

.social_media_bright:hover {
    color: var(--button_color_bright);
    cursor: pointer;
}

.social_media_dark:hover {
    color: var(--button_text_color_hover_dark);
    background-color: white;
    cursor: pointer;
}