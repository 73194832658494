.welcome_div_mobile{
    width: 60%;
    margin-left: 15%;
    margin-right: 15%;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 15vh;
    font-size: 12px;
}

.div_profile_picture-first-page_mobile_horizontal {
    float: left;
}

.introduction_div_horizontal{
    float: right;
}

.profile_picture-first-page_mobile{
    width: 30%;
    border-radius: 20%;
    padding: 20px;
    transition: width 2s;
}

.profile_picture-first-page_mobile:hover{
    width: 20%;
    direction: inherit;
}

.introduction_div_mobile{
    font-size: 15px;
    margin-left: 5%;
    margin-right: 5%;
    padding: 2vh 2vh 8vh;
}

.self_description_div_mobile{
    font-size: 15px;
}

.slider_div_mobile{
    height: 34vh;
}