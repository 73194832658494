@import "../../styles/fonts.css";
@import "../../styles/colors.css";

#subdiv_medium {
    margin-left: 1%;
    margin-right: 1%;
    border-radius: 5%;
}

.master_div_medium {
    margin-top: 8vh;
    padding-bottom: 10vh;
}

.main_content_div_medium {
    margin-right: 20%;
    margin-left: 20%;
}

.normal_text_bright {
    size: var(--size_normal_text);
    color: var(--text_color_bright);
}

.normal_text_dark {
    size: var(--size_normal_text);
    color: var(--text_color_dark);
}

#subdiv.small {
    margin-left: 1%;
    margin-right: 1%;
    border-radius: 5%;
}

.default_text_div_medium {
    font-size: 17px;
    font-family: Arial, Helvetica, sans-serif;
}

.h3_medium{
    font-size: 19px;
    font-family: Arial, Helvetica, sans-serif;
}
