.welcome_div_large{
    width: 50%;
    margin-left: 20%;
    margin-right: 20%;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 20vh;
    font-size: 25px;
}

.div_profile_picture-first-page_large {
    width: 100%;
    float: left;
}

.introduction_div_large {
    font-size: 25px;
    margin-left: 0;
    width: 120%;
    margin-right: 10%;
    float: right;
    padding: 2vh 2vh 8vh;
}

.start_page_div_container_large_horizontal {
    display: flex;
}

.start_page_div_container_large_vertical {
    display: flex;
}

.profile_picture-first-page_large{
    margin-top: 4vh;
    height: 30%;
    border-radius: 10%;
    margin-right: -60%;
    transition: height 2s;
}

.profile_picture-first-page_large:hover{
    height: 70%;
    direction: inherit;
}

.self_description_div_large{
    margin-top: -5vh;
    font-size: 30px;
}

.slider_div_large {
    height: 32vh;
}

.welcome_header_large {
    font-size: 3vh;
}
