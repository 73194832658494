.footer_mobile {
    height: 3vh;
}

.copyright_box_mobile{
    left: 1vh;
    font-size: 8pt;
    padding: 0
}

.social_media_mobile{
    font-size: 1.5em;
    margin-top: -1.5vh;
    margin-left: 1vh;
}

.logo_box_mobile{
    right: 15vh;
}