.change_color_icon_mobile{
    margin-top: 1.8vh;

    margin-left: 1vh;
    font-size: 3vh;
    padding: 0.3vh;
}

.side_bar_selector_mobile{
    margin: 1.2vh 0 1vh 2vw;
    width: 3vh;
    height: 3vh;
    padding: 1vh;
}

.header_mobile{
    width: 100%;
    height: 7vh;
}

.side_bar_stripe_mobile{
    height: 0.4vh;
    width: 100%;
    margin: 0.2vh 0.0vw 0.7vh;
    pointer-events: none;
}


