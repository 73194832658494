.main-content-div_small {
    margin-right: 20%;
    margin-left: 20%;
}

.master_div_small{
    margin-top: 7vh;
    padding-bottom: 10vh;
}

.main_content_div_small {
    margin-right: 10%;
    margin-left: 10%;
}

.subdiv_small {
    margin-left: 1%;
    margin-right: 1%;
    border-radius: 5%;
}

.default_text_div_small {
    font-size: 12px;
}

.h3_small{
    font-size: 14px;
}

body{
    font-family: Arial, Helvetica, sans-serif;
}